.toast {
  position: fixed;
  transform: translate(-50%, -0%);
  padding: 6px 12px;
  left: 50%;
  font-size: 14px;
  font-weight: 500;
  color: white;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.15);
  animation-fill-mode: forwards;
  max-width: 400px;
}

.toast-top {
  top: 15px;
  animation: SlideTopDown 0.4s;
}

.toast-bottom {
  bottom: 20px;
  animation: SlideBottomUp 0.4s;
}

.toast-success {
  background-color: rgb(83, 168, 64);
}

.toast-failure {
  background-color: rgb(193, 60, 55);
}

.toast-default {
  background-color: rgb(40, 40, 40);
}

.toast-warning {
  background-color: #ffba27;
}

@keyframes SlideTopDown {
  0% {
    margin-top: -50px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes SlideBottomUp {
  0% {
    margin-bottom: -30px;
  }

  100% {
    margin-bottom: 0px;
  }
}